import React from 'react';
import { graphql } from 'gatsby';

import Head from '@/components/Head';
import Page from '@/components/Page';
import ProjectHeader from '@/components/ProjectHeader';
import ModulesLoop from '@/components/ModulesLoop';

export default ({
  data: {
    project: { title, meta, areasOfWork, roles, contentModules },
  },
}) => {
  const taxonomies = [];

  if (areasOfWork) {
    taxonomies.push({
      id: 'areaofwork',
      title: 'Areas of Work',
      items: areasOfWork.map(item => item.title),
    });
  }

  if (roles) {
    taxonomies.push({
      id: 'role',
      title: 'Roles',
      items: roles.map(item => item.title),
    });
  }

  return (
    <>
      <Head title={title} meta={meta} />

      <Page background="white">
        <ProjectHeader title={title} taxonomies={taxonomies} />

        <ModulesLoop contentModules={contentModules} />
      </Page>
    </>
  );
};

export const query = graphql`
  query($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      title
      meta {
        ...metaData
      }
      areasOfWork {
        title
      }
      roles {
        title
      }
      contentModules {
        ...contentModulesData
      }
    }
  }
`;
