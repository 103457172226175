import React from 'react';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';

import Separator from '@/components/Separator';
import Columns from '@/components/Columns';

import theme from '@/styles/theme';

export default ({ title, taxonomies, ...props }) => {
  const taxonomyColumns = taxonomies.map(taxonomy => ({
    ...taxonomy,
    type: 'atomList',
  }));

  return (
    <Grid.Container pb={theme.spacing.large} pt={[2, 2, 0]} {...props}>
      <Grid>
        <Grid.Item as="header" w={[1, 1, 1 / 2]}>
          <Heading as="h1" h={3} mb={[3, 3, 1.75]}>
            {title}
          </Heading>
        </Grid.Item>

        <Grid.Item w={[1, 1, 1 / 2]}>
          <Separator label="Project Details" mb={theme.spacing.xsmall} />

          <Columns
            columnsPerRow={2}
            columnWidth={[1, 1, 1, 1, 1 / 2]}
            columns={taxonomyColumns}
          />
        </Grid.Item>
      </Grid>
    </Grid.Container>
  );
};
